<template>
  <v-container>
    <h2>
      <router-link to="/admin/events">Bootcamp Events</router-link> ›
      {{ event_date }}
    </h2>

    <user-table
      :loading="loading"
      :agent_data="agent_data"
      :filter_menu="false"
      index="index"
    ></user-table>
  </v-container>
</template>

<style scoped>
.v-text-field {
  max-width: 400px;
}
</style>

<script>
import UserTable from "../../components/Admin/UserTable.vue";

import gql from "graphql-tag";
import dayjs from "dayjs";

export default {
  name: "AgentTable",
  components: { UserTable },

  data: () => ({
    event_id: null,
    event_date: "Loading...",
    loading: false,
    agent_data: [],
  }),

  created() {
    this.event_id = this.$route.params.event_id;
    this.initialize();
  },

  methods: {
    formatDate(date) {
      return dayjs(date).format("MM/DD/YYYY");
    },
    async setEvent(id) {
      const { data } = await this.$apollo.query({
        query: gql`
          query BootCampDetail($event_id: Int!) {
            boot_camp_events_by_pk(id: $event_id) {
              event_date
            }
          }
        `,
        variables: {
          event_id: id,
        },
      });
      this.event_date = this.formatDate(data.boot_camp_events_by_pk.event_date);
    },
    async initialize() {
      this.loading = true;
      this.setEvent(this.event_id);

      const { data } = await this.$apollo.query({
        query: gql`
          query BootCampDetails($bcid: Int!) {
            boot_camp_attendees(where: { bce_id: { _eq: $bcid } }) {
              user {
                admin
                phone
                name
                hidden
                status
                id
                created_at
              }
            }
          }
        `,
        variables: {
          bcid: this.event_id,
        },
      });

      let _agent_data = data.boot_camp_attendees.map((a) => {
        return a.user;
      });

      // _agent_data = [...new Set(_agent_data)];

      this.agent_data = _agent_data.map((item, index) => {
        item.marketer_clean = "Not Implmented";
        item.allowed_carriers_cnt = 0;

        return {
          ...item,
          index,
        };
      });

      this.loading = false;
    },
    handleClick: function(e) {
      this.$router.push(`/admin/agents/${e.id}`);
    },
  },
};
</script>
